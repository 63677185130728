@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppinsdisplay=swap');

.const{
  background-image: url("./assets/constructionbg.jpg");
  background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-color: darkblue;
    
}
.headc{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 800;
font-size: 70px;
line-height: 73px;
margin-top: 7rem;
text-align: center;
text-transform: capitalize;

color: #000000;
}
.work{
  background-image: linear-gradient(#1E242D,#222831);
  background-size: cover;
  margin: 0;
  padding: 0;
  padding-bottom: 5em;
}
#heh3{
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 54px;
/* line-height: 81px; */
/* identical to box height */
padding-top: 2em;
padding-left: 1em;

display: flex;
color: white
}

 /* working section cards */
 .work-flex{
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 3em 0 1em 0;
 }
   .paln{
    background: linear-gradient(135deg, #1E242D 0%, #222831 100%);
box-shadow: -70px 70px 140px rgba(19, 22, 27, 0.2), 70px -70px 140px rgba(19, 22, 27, 0.2), -70px -70px 70px rgba(45, 54, 67, 0.5), 70px 70px 80px rgba(19, 22, 27, 0.5), inset 3px 3px 4px rgba(45, 54, 67, 0.3), inset -3px -3px 4px rgba(19, 22, 27, 0.5);
border-radius: 24px;
width: 340px;
height: 340px;
   }

#bg{
  padding-top: 2em;
  padding-left: 2em;
}
.phead{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 2em;
line-height: 54px;
padding-top: 1em;
padding-left: 1em;
display: flex;
align-items: center;
text-transform: capitalize;

color: #FFFFFF;

}
.ppara{
  width: 294px;
height: 81px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 27px;
text-transform: capitalize;
padding-top: 1em;
padding-left: 1.7em;
color: #FFFFFF;
}
/* Stats  */
.stat,.workstruct{
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 4em; 
padding-top: 1em;

/* identical to box height */
margin-top: 2em;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
text-transform: capitalize;

color: white;
}
.workstruct{
margin-top: 0;
}
.stat-flex{
  display: flex;
  gap: 8em;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 3em 0 3em 0;
}
.statc{
  background: linear-gradient(135deg, #1E242D 0%, #222831 100%);
box-shadow: -70px 70px 140px rgba(19, 22, 27, 0.2), 70px -70px 140px rgba(19, 22, 27, 0.2), -70px -70px 140px rgba(45, 54, 67, 0.9), 70px 70px 175px rgba(19, 22, 27, 0.9), inset 3px 3px 4px rgba(45, 54, 67, 0.3), inset -3px -3px 4px rgba(19, 22, 27, 0.5);
border-radius: 24px;
width: 340px;
height: 340px;
display: flex;
justify-content: center;
flex-direction: column;
}
.s-num{

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
}
.stathead{

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */
  padding: 1em 2em;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  justify-content: center;
  color: #FFFFFF;
}
/* testimonial */
.testtc{
  width: 430px;
height: 350px;
left: 124px;
top: 2988px;

background: linear-gradient(135deg, #1E242D 0%, #222831 100%);
box-shadow: -70px 70px 140px rgba(19, 22, 27, 0.2), 70px -70px 140px rgba(19, 22, 27, 0.2), -70px -70px 140px rgba(45, 54, 67, 0.9), 70px 70px 175px rgba(19, 22, 27, 0.9), inset 3px 3px 4px rgba(45, 54, 67, 0.3), inset -3px -3px 4px rgba(19, 22, 27, 0.5);
border-radius: 24px;
}
.quote{
  font-family: 'Playfair Display SC';
font-style: normal;
font-weight: 400;
font-size: 7em;

display: flex;
align-items: center;
text-align: center;
justify-content: center;
text-transform: capitalize;

color: #FFFFFF;

opacity: 0.6;
}
.tpara{
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 1em;
line-height: 27px;
display: flex;
padding: 0em 4em 2em;
margin-top: -3em;
align-items: center;
text-align: end;
text-transform: capitalize;

color: #FFFFFF;
}
.byname{
  
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
/* identical to box height */
margin-top: 20px;

display: flex;
align-items: center;
text-align: center;
justify-content: center;
text-transform: capitalize;

color: #FFFFFF;
}
.byname2{
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  /* identical to box height */
  
  
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  
  color: #FFFFFF;
  }
/* video cards */

.context{
    color:#04619f ;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 93px;
/* width: 900px; */
margin: 0 20%;
display: grid;
grid-template-columns: auto;
align-items: center;
text-align: center;
justify-content: center;
text-transform: capitalize;
padding-top: 1em;

}
h3{
    color: #000000;
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    margin: 2em;
}
.seccard{
    height: 100vh;
}

body{
  
  background-color: rgba(209, 231, 240);

    
  }

  .box{
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 15px;
    margin: 0 auto;
    margin-top: 5em;
    margin-bottom: 5em;
   
  }
  .card{
    position: relative;
    width: 390px;
    height: 350px;
    background-color: #000000;
    background-image: linear-gradient(#1E242D,#222831);

    margin: 0 auto;
    border-radius: 4px;
    box-shadow:0 2px 10px rgba(0,0,0,.2);
  }
  .card:before,
  .card:after
  {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #000000;
    background-image: linear-gradient(#1E242D,#222831);

    transition: 0.5s;
    z-index:-1;
  }
  .card:hover:before{
  transform: rotate(20deg);
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  }
  .card:hover:after{
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  }
  .card .imgBx{
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background-image: linear-gradient(#1E242D,#222831);

  transition: 0.5s;
  z-index: 1;
  }
  
  .card:hover .imgBx
  {
    bottom: 80px;
  }

  .card .imgBx video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    
  }

  .card .details{
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      height: 60px;
      text-align: center;
  }

  .card .details h2{
   margin: 0;
   padding: 0;
   font-weight: 600;
   font-size: 20px;
   color: white;
   text-transform: uppercase;
  } 

  .card .details h2 span{
  font-weight: 700;
  font-size: 16px;
  color: #8edaf8;
  display: block;
  margin-top: 5px;
   } 
   @media screen and (max-width:600px) {
        .box{
            display: grid;
            grid-template-columns: auto;
            width: 100vw;
            height: 100vh;
             grid-gap: 40px;

        }    
        #heh3{
          text-align: center;
          justify-content: center;
          align-items: center;
          padding-left: 0;

        }
        .headc{
          font-size: 3.5em;
          margin-top: 4rem;
        }
        .work-flex , .stat-flex{
          display: grid;
          grid-template-columns: auto;
          justify-content: center;
          column-gap: 2em;
        }
        .stat-flex{
          display: grid;
          grid-template-columns: auto;
          column-gap: 3em;
          justify-content: center;
          text-align: center;
          align-items: center;
          justify-items: center;
          /* margin: 3em 0 3em 0; */
        }
        .stat{
          font-size: 3.3em;
        }
        .testtc{
          width: 90%;
          height: 500px;
          margin-left: 1em;
        }
        .tpara{
          font-size: 0.9em;
          /* line-height: 27px; */
          /* display: flex; */
          padding: 0em 1.5em 2em;
        }
        .context{
          font-size: 2.5em;
          margin-left: 0.3em;
          width: 96%;
        }
        .seccard{
            height: fit-content;
            background-size: cover;
        }
        #cfoot{
            margin-top: 21em;
        }
        
   }

