@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@200&display=swap');
.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 2.6rem 0rem;
    background-color: rgba(209, 231, 240);
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    /* // removes default disc bullet for li tags and applies margin to left & right side */
    list-style-type: none;
    margin: 2rem 1rem;
  }
  .navigation-menu li a {
    /* // increases the surface area of the anchor tag to span more than just the anchor text */
    text-decoration: none;
    display: block;
    width: 100%;
  }
  .hamburger {
    /* // removes default border on button element */
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  /* // positions the icon to the right and center aligns it vertically */
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #2642af;
  }
  ul {
    font-family: 'Poppins';
    text-align: center;
    font-weight: 500;
    letter-spacing: 1px;
  }
ul * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
  ul li {
    display: inline-block;
    list-style: outside none none;
    margin: 0.4em 0.8em;
    padding: 0;
  }
  ul a {
    padding: 0 0.7em;
    color: rgba(0, 0, 0, 0.9);
    position: relative;
    text-decoration: none;
    display: inline-block;
  }
  ul #hoveer:before {
    position: absolute;
    content: '';
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    opacity: 0;
    left: 15%;
    right: 15%;
    top: 0;
    bottom: 0;
    border-left: 5px solid darkblue;
    border-right: 5px solid darkblue;
  }
  ul #hoveer:hover {
    color: darkblue;
  }
  ul #hoveer:hover:before{
    opacity: 1;
    left: 0;
    right: 0;
  }
#wpbut{
  margin: 0rem 1.5rem;
  padding-top: 0.4rem;
}
#wpbut:hover{
  filter: drop-shadow(8px 8px 19px rgba(41,44,49,0.22));
}
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
    .navigation-menu ul {
        display: none;
      }
  
      .navigation-menu li {
        /* // centers link text and strips off margin */
        text-align: center;
        margin: 0;
        display: block;
        /* overflow: hidden;
        z-index: 1; */
      }
      .navigation-menu ul {
        /* // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar) */
        position: absolute;
        overflow: hidden;
        z-index: 1;
        top: 80px;
        left: 0;
        /* // stacks the li tags vertically  */
        flex-direction: column;
       /* // makes menu span full height and width */
        width: 100%;
        height: fit-content;
        background-color: rgba(4, 38, 51);;
        border-top: 1px solid black;
      }
      .navigation-menu li #hoveer {
        color: white;
        /* // increases the surface area of the anchor tag to span the full width of the menu */
        width: 100%;
        padding: 1.5rem 0;
        display: block;
      }
      .navigation-menu li:hover {
        background-color:gray;
      }
      .navigation-menu.expanded ul {
        display: block;
      }
      #wpbut{
        margin:  1.5rem 0;
      }
    }
  
    #hire-us-button {
      background-color: #4CAF50; /* Example button background color */
      color: white;
      padding: 10px 20px;
      text-align: center;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border: 2px solid #4CAF50; /* Add border color */
      border-radius: 5px; /* Optional: for rounded corners */
      transition: 0.3s;
    }
    
    #hire-us-button:hover {
      background-color: white;
      color: #4CAF50;
    }