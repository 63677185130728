.service{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    animation: bg 10s  infinite alternate ;
    animation-fill-mode: forwards;
    
}
@keyframes bg {
    0% {
      background-image: url('https://c4.wallpaperflare.com/wallpaper/973/1004/995/interior-design-room-carpets-couch-wallpaper-preview.jpg');
      
    }
    50% {
      background-image: url('https://c4.wallpaperflare.com/wallpaper/480/897/69/room-blue-furniture-couch-wallpaper-preview.jpg');
     
     
    }
    100% {
   
    background-image: url("https://i.pinimg.com/originals/ab/57/e7/ab57e7728808d24d6d04570aa9b0808b.jpg");

   
    }
  }
html{
    scroll-behavior: smooth;
}
.explore{
    text-align: center;
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-items: center;
}
.explore h2{
   
    /* color: white;
    background-color: rgba(128, 128, 128, 0.094); */
    margin-top: 10%;
    margin-left: 40%;
    padding: 0.3em;
    font-size: 2.4em;
    font-weight: bold;
    border: 8px solid transparent;
    animation: borde infinite 3s alternate forwards;
    background-image:
        linear-gradient(rgba(255, 255, 255, 0.799), rgba(255, 255, 255, 0.558)),
        linear-gradient(180deg, rgb(72, 103, 161), rgb(68, 79, 239) 50%, rgb(100, 105, 237));
    
      background-repeat: no-repeat;
      background-size: 100% 100%, 100% 200%;
      background-position: 0 0, 0 100%;
      background-origin: padding-box, border-box;
    
     
}


@keyframes borde     {
    100% {

      }
}
#arroww{
    float: right;
    margin-right: 25em;
    margin-top: 5em;
    height: 8em;
    background-color: white;
    border-radius: 50%;
    scroll-behavior: smooth;
    cursor:pointer;
    font-weight: 900;
}
.gridsec{
    margin-top: 26em;
    padding-top: 2em;
    padding-bottom: 2em;
    /* margin-bottom: 10em; */
    background-color: #dbe7fc;
    min-height: 100vh;
    background-size: max-content;
}

@media screen and (max-width:600px) {
    .service{

        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        width: 100vw;
        background-image: url('https://media.istockphoto.com/photos/purple-blue-wall-living-room-interior-2022-very-peri-3d-rendering-picture-id1358348907?b=1&k=20&m=1358348907&s=170667a&w=0&h=qn97phnipn3IJbn79TFWd89GkxYEgS9FIi2lWnIHgf4=');
        animation: none;
    } 
    .explore h2{
   

    margin-top: 15%;
    margin-left: 0%;
    padding: 0.3em;
    font-size: 2.3em;
    font-weight: bold;
    text-align: center;
    
}

#arroww{
text-align: center;
    margin-top: 5em;
    margin-right: 10em;
    height: 5em;
    background-color: aliceblue;
    border-radius: 50%;
}
.gridsec{
    margin-top: 36em;
    min-height: 100vh;
    padding-top: 0.2em;
    padding-bottom: 0.9em;
}
.servicesec{
    margin-top: -5em;
}

}