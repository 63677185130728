@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@200&display=swap');
.App {
  text-align: center;
  font-family: 'Poppins';

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html,body{
  scroll-behavior: smooth;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/* home */

@import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap");


body, html {
  overflow-x: hidden;
  /* overflow-y: scroll; */
}

.containerr {
  width: 100%;
  height: 100vh;
  /* background: transparent;s */
  display: flex;
  justify-content: center;
  align-items: center;
z-index: 0;

}
.containerr .box {
  width: 350px;
  height: 250px;
  /* position: relative; */
  display: flex;
  justify-content: center;
  flex-direction: column;
 
}
.containerr .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
  text-align: center;
}
.containerr .box .title .block {
  width: 0%;
  height: inherit;
  background: darkblue;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}
.containerr .box .title h1 {
  font-family: "Poppins";
  color: black;
  font-size: 60px;
  text-align: center;
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
  padding-left: 10px;
}
.containerr .box .title h1 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: darkblue;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
}
.containerr .box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: -5px;
  text-align: center;
  margin-left: -0.3em;
}
 .box .role .block {
  width: 0%;
  height: inherit;
  background: #e91e63;
  position: absolute;
  animation: secBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 2s;
  display: flex;
}
.box .role p {
  animation: secFadeIn 2s forwards;
  animation-delay: 3.2s;
  opacity: 0;
  font-weight: 400;
  font-family: "Lato";
  color: black;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
  width: 100%;
}
.tagline p{
  color: darkblue;
  background-color: white;
  font-size: 32px;
  position: relative;
  display: flex;
  align-items: center;
  /* align-items: center; */
  justify-items: center;
  /* margin-left: -20px;*/
  text-align: center;
  font-weight: bold;
}

#tag{
  width: 200%;
  text-align: center;
 margin-top: 2em;
 margin-left: -2em;
}
/* @import url("https://fonts.googleapis.com/css?family=Sacramento&display=swap"); */

.tagline p{

/*   text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
    0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092;
  color: #fccaff; */
  text-shadow: 0 0 5px #c5b1e480, 0 0 10px #00b7ff92, 0 0 10px #d400ffad, 0 0 40px #d400ffad, 0 0 60px #ff0000, 0 0 10px #9bcaed, 0 0 98px #68c8f1;
    color: darkblue;
  /* font-family: "Sacramento", cursive; */
  text-align: center;
  animation: blink 12s infinite;
  -webkit-animation: blink 12s infinite;
}


@-webkit-keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
/*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
      text-shadow: 0 0 5px #c5b1e480, 0 0 10px #00b7ff92, 0 0 10px #d400ffad, 0 0 40px #d400ffad, 0 0 60px #0022ff3d, 0 0 10px #9bcaed, 0 0 98px #68c8f1;
    color: darkblue;
  }
}

@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
/*     color: #fccaff;
    text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
      0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
  /* text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500, 0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000; */
    color: darkblue;
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: darkblue;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: darkblue;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: darkblue;
    border: 0px solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}



.center {
  display: flex;
  width: 100vw;
  height:100vh;

  background-image: url(https://wallpapershome.com/images/pages/ico_h/20330.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: image 10s  infinite alternate ;
  animation-fill-mode: forwards;

}


@keyframes image {
  0% {
    background-image: url('./assets/wall3.webp');
  }
  50% {
    background-image: url('./assets/wallpaper.jpg');
   
  }
  100% {
    background-image: url('./assets/wall2.webp');
  }
}

 .center__text {
  margin: auto;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  text-transform: uppercase;
}

.glitch {
  position: relative;
  color: black;
  mix-blend-mode: darken;
 
}
.getstart{
margin-top: 1em;
text-align: center;
cursor: pointer;


}
#star{
  text-align: center;
  background-color: darkblue;
  color: white;
  margin-top: 0em;
margin-left: -2em;
  font-size: 0.4em;
font-weight: 700;
  cursor: pointer;
  padding: 1em;
  border: transparent 2px solid;
  border-radius: 8px;
}
#star:hover, #serv:hover{
  box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
  0 2px 2px rgba(0,0,0,0.11), 
  0 4px 4px rgba(0,0,0,0.11), 
  0 8px 8px rgba(0,0,0,0.11), 
  0 16px 16px rgba(0,0,0,0.11), 
  0 32px 32px rgba(0,0,0,0.11);
transition: all 0s ease-in-out;
border: rgba(255, 255, 255, 0.066) 2px solid;
background-image: linear-gradient(315deg, #1912d6 25%, #020f21 74%);
font-weight: 700;
color: white;
}

.heading{

color: darkblue;
  font-size: 2em;
  text-align: center;
  padding-top: 1em;
  font-weight: 600;
  padding-bottom: 1em;
}
#head{
  background-color: darkblue;
  color: white;
}
#abouts{

  background-color: #b8c6db;
background-image: linear-gradient(315deg, #578ee1 25%, #f5f7fa 74%);


}
.flexhero{
  display: flex;
  gap: 6em;
 
margin-left: 0;
margin-top: 1em;
padding-top: 1.5em;
}
.image{
margin: 2em 4em;
}
#img{
  height: 500px;
  width: 500px;
  border: 2px rgba(18, 1, 1, 0.153) solid; 
  box-shadow:
  2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07)
;
  border-radius: 16px;
}
.nam{
  margin-top: 0.5em;
  color:darkblue;
  font-weight: 700;
}
#h33{
  margin-top: 0.2em;
  font-weight: 600;
}
.text{
  padding: 4em 0;
  width: 700px;
  margin-right: 2em;

}
.para{
    font-size: 1.4em;
    color: rgba(0, 0, 0, 0.589);
    font-weight: 700;

}
.link{
  margin-top: 2em;
}
#serv{
  background-color: darkblue;
  color: white;
  font-size: 1.2em;
  padding: 0.8em 1em;
  text-decoration: none;
  z-index: 1;
  border: transparent 2px;
  border-radius: 8px;
  box-shadow: 0 6px 6px rgba(0,0,0,0.2);
  cursor: pointer;
  font-weight: 700;
  

}


/* card */
.flexcard{
  display: grid;
  margin-top: 2em;
  margin-bottom: 2em;
  grid-template-columns: repeat(3,1fr);
  text-align: center;
  align-items: center;
  justify-items: center;
}

/* interior section */
#isec{
  background-color: #1d2951;
  background-image: linear-gradient(315deg, #1d2951 0%, #dbe7fc 74%);
}

#flexint{
justify-content: center;
justify-items: center;
align-items: center;
display: grid;
grid-template-columns: auto auto;
}
#interior{
  height: 400px;
  width: 400px;
}

/* footer */
.foot{
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  justify-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #e4edfa;
}
.footer{
  display: grid;
grid-template-columns: repeat(3,1fr);
 
  padding: 2em;
  
 
}
.fp{
  color: white;
  font-weight: bold;

}
.flink{
  display: block;
  block-size: 1em;

  
}

.flink a:hover, #foothover:hover{
  color: #f9f4f4;
  font-weight: 700;
}
.addr{
  text-align: left;
}
i{
  margin-right: 1em;
}

@media screen and (max-width:600px) {
  .containerr {
    margin-top: -2em;
    z-index: 0;
    white-space: normal;
  }
  .foot{
    font-size: 0.9rem;
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
    text-align: left;
    background-size: cover;
    width: 100%;
  }
  .footer{
    display: grid;
    grid-template-columns: auto;
    float: left;
    padding-left: 1.5em;
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  }
  .fp{
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    text-align: center;
    padding-bottom: 1em;
  }
  
  .addr{
    text-align: left;
    margin-top: 9em;
  }
  /* home page tagline */
  #tag{
   text-align: center;
    font-size: 30px;
    margin-left: -0.5em;
  }
  /* about */
  .flexhero{
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-items: center;
 
    margin-left: 0;
    margin-top: 1em;
    padding-top: 1.5em;
  }
  .image{
    margin: 2em 0em;
  }
  #img{
  height: 380px;
  width: 380px;
  }
  .text{
    padding: 0em 0;
    width: 100%;
    /* margin-right: 2em; */
 
  }
  .para{
    margin-top: -5em;
      font-size: 1.1em;
      color: rgba(0, 0, 0, 0.589);
      font-weight: 600;
      padding: 0 2em;

  }
  .link{
    margin: 3em 2em;
    margin-bottom: 2em;
  }
  /* #serv{
   
  } */
  .heading{
    font-size:1.3em ;
  }
  .flexcard{
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
  }
  #flexint{
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: grid;
    grid-template-columns: auto ;
    }
    @keyframes image {
      0% {
        background-image: url('./assets/mwallpaper.jpg');
      }
      50% {
        background-image: url('./assets/wallpaper.jpg');
       
      }
      100% {
        background-image: url('./assets//wall2.webp');
      }
    }
}
html{
  scroll-behavior: smooth;
}
@media screen and (max-width:900px) and (min-width:500px) {
.flexhero{
  display: grid;
  column-gap: 0em;
  grid-template-columns: auto;
  align-items: center;
  justify-items: center;
}
.image{
  margin: 2em 0em;
  }
#img{
  height: 95%;
  width: 95%;
}
}
