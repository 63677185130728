html, body {
    height: 100%; /* Ensure the html and body elements take the full height */
    margin: 0; /* Remove default margin */
}

.wrapper {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    min-height: 100vh; /* Make the wrapper take at least the full height of the viewport */
}

.content {
    flex: 1; /* Allow the content area to grow and fill available space */
}

.footer {
    padding: 20px; /* Add some padding */
    background-color: #333; /* Footer background color */
    color: white; /* Footer text color */
    text-align: center; /* Center align text */
    /* Ensure it takes full width */
    width: 100%;
    box-sizing: border-box; /* Include padding in the element's total width */
}
